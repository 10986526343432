import React from "react";
import "./Category.css";
import {TiTick} from "react-icons/ti";

export default function Category() {
    return (
        <>
            <div className="category">
                <div className="category_inner">
                    <div className="category_row1">
                        <h2>Home</h2>
                        <span>*</span>
                        <h2>Office</h2>
                        <span>*</span>
                        <h2>Apartment</h2>
                    </div>
                    <div className="category_row2">
                        <label>Aaaaa aaaaa aaaaaa aaaaaaa aaaaa aaaaaa aaa</label>
                        <label>Aaaaa aaaaa aaaaaa aaaaaaa aaaaa aaaa aaa</label>
                        <label>Aaaaa aaaaa aaaaaa aaaaaaa aaaaa aaa aaaaa</label>
                    </div>
                    <div className="category_row3">
                        <div className="category_row3_col1">
                            <TiTick sty/>
                            <label>Weekly</label>
                        </div>
                        <div className="category_row3_col1">
                            <TiTick/>
                            <label>Bi-Weekly</label>
                        </div>
                        <div className="category_row3_col1">
                            <TiTick/>
                            <label>Monthly</label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}