import React from "react";
import "./Homepage.css"
import Header from "../components/Header";
import Category from "../components/Category";
import Type from "../components/Types";
import Banner1 from "../components/Banner1";
import Banner2 from "../components/Banner2";
import Savebar from "../components/Savebar";
import img from "../image/left.jpg";

export default function Homepage() {
    return (
        <>
            <div className="homepage">
                {/* <div className="homepage_col1">
                    <img src={img}/>
                </div>
                <div className="homepage_col2"> */}
                    <Header />
                    <Category />
                    <Type />
                    <Banner1 />
                    <Banner2 />
                    <Savebar />
                {/* </div> */}
            </div>
        </>
    )
}