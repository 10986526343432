import React from "react";
import img2 from "../image/t6.jpg";
import t5 from "../image/t5.jpg";
import t2 from "../image/t2.jpg";
import t4 from "../image/t4.jpg";
import {FiThumbsUp} from "react-icons/fi";
import "./Types.css";

export default function Type() {
    return (
        <>
            <div className="Type_component">
                <SingleType  img={img2}/>
                <SingleType img={t5}/>
                <SingleType img={t2}/>
                <SingleType img={t4}/>
            </div>
        </>
    )
}

function SingleType({img}) {
    return (
        <>
            <div className="singletype">
                <div className="singletype_row1">
                    <img src={img} />
                </div>
                <div className="singletype_row2">
                    <h2>House cleaning</h2>
                </div>
                <div className="singletype_row3">
                    <label>Aaaaa aaaa aaaa aaaaaaa</label>
                    <label>Aaaaa aaaa aaaa aaaaaaa</label>
                    <label>Aaaaa aaaa aaaa aaaaa</label>
                    <label>Aaaaa aaaa aaaa aaaaaaa</label>
                    <label>Aaaaa aaaa aaaa </label>
                    <div className="singletype_row3_row">
                    <label>Read More</label>
                    <FiThumbsUp/>
                </div>
                </div>
            </div>
        </>
    )
}