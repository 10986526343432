import React from "react";
import "./Banner2.css";
import img1 from "../image/t7.jpg";
import { FiThumbsUp } from "react-icons/fi";
import { IoPricetagsOutline } from "react-icons/io5";
import { GrUserManager } from "react-icons/gr"

export default function Banner1() {
    return (
        <>
            <div className='banner'>
                <div className="banner_inner">

                    <div className='banner_col1'>
                        <div className='banner_col1_row1'>
                            <h2>Eco-friendly products</h2>
                            <label className='banner_col1_row1_inlab'>safe for your family</label>
                        </div>
                        <div className='banner_col1_row2'>
                            <label>Aaaaaa aaaaaaaaaaaa aaaaaaaaa aaaaaaa aaaaa</label>
                            <label>aaaaaa aaaaaaaaaaaa aaaaaaaaa aaaaaaa aaaaaa</label>
                            <label>aaaaaa aaaaaaaaaaaa aaaaaaaaa.</label>
                        </div>
                        {/* <div className='banner_col1_row3'>
                            <div className="banner_col1_row3_row1">
                                <GrUserManager />
                                <label>Aaaaaaaaa aaaaaaaaaa</label>
                            </div>
                            <div className="banner_col1_row3_row1">
                                <IoPricetagsOutline />
                                <label>Aaaaaaaaa aaaaaaaaaa</label>
                            </div>
                            <div className="banner_col1_row3_row1">
                                <FiThumbsUp />
                                <label>Aaaaaaaaa aaaaaaaaaa</label>
                            </div>
                        </div> */}
                    </div>
                    <div className='banner_col2'>
                        <div>
                            <img src={img1} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}