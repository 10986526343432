import React from "react";
import "./Savebar.css";
import {FiMessageCircle} from "react-icons/fi";
import {FiUpload} from "react-icons/fi";
 import {MdCenterFocusWeak} from "react-icons/md";
 import {IoSearchOutline} from "react-icons/io5";

export default function Savebar(){
    return(
        <>
        <div className="savebar">
            <div className="savebar_row1">
                <div className="savebar_row1_circle">
                    <MdCenterFocusWeak style={{fontSize:30}}/>
                </div>
            </div>
            <div className="savebar_row2">
                <div className="savebar_row2_col1">
                    <FiMessageCircle style={{fontSize:50}}/>
                    <span>1</span>
                </div>
                <div className="savebar_row2_col2">
                    <button>Visit</button>
                </div>
                <div className="savebar_row2_col3">
                    <button>Save</button>
                </div>
                <div className="savebar_row2_col4">
                    <FiUpload style={{fontSize:50}}/>
                    <span>0</span>
                </div>
            </div>
        </div>
        </>
    )
}