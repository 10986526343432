import './Header.css';
import { BsWhatsapp } from "react-icons/bs";
import arrow from "../image/arrow1.png";
import { BsThreeDots } from "react-icons/bs";
import img1 from "../image/t3.jpg";
import img from "../image/left.jpg"

function Header() {
  return (
    <><div className='Header_outer'>
      <img src={img}/>
      <div className='Header'>
        <div className='Header_row1'>
          <div className='Header_row1_col1'>
            <h2>𝑱𝒆𝒏𝒏𝒚'𝒔</h2>
            <label>Aaaaaa Aaaaaa</label>
          </div>
          <div className='Header_row1_col2'>
            <label>HOME</label>
            <label>HOME</label>
            <label>HOME</label>
            <label>HOME</label>
          </div>
          <div className='Header_row1_col3'>
            <div className='Header_row1_col3_button'>
              <BsWhatsapp />
              <label>9444515523</label>
            </div>
          </div>
        </div>
        <div className='Header_row2'>
          <div className='Header_row2_col1'>
            <div className='Header_row2_col1_circle'>
              <img src={arrow} />
            </div>
          </div>
          <div className='Header_row2_col1'>
            <BsThreeDots style={{ fontSize: "50", color: "white" }} />
          </div>
        </div>
        <div className='Header_row3'>
          {/* <div className='Header_row3_inner'> */}
          <div className='Header_row3_col1'>
            <div className='Header_row3_col1_row1'>
              <h2>We will customize a</h2>
              <h2>cleaning service <label className='Header_row3_col1_row1_inlab'>just for you</label></h2>
            </div>
            <div className='Header_row3_col1_row2'>
              <label>Aaaaaa aaaaaaaaaaaa aaaaaaaaa aaaaaaa aaaaa</label>
              <label>aaaaaa aaaaaaaaaaaa aaaaaaaaa aaaaaaa aaaaaa</label>
              <label>aaaaaa aaaaaaaaaaaa aaaaaaaaa.</label>
            </div>
            <div className='Header_row3_col1_row3'>
              <button className='Header_row3_col1_row3_btn1'>Aaaaaaaaaaa</button>
              <button className='Header_row3_col1_row3_btn2'>Aaaaaaaa</button>
            </div>
          </div>
          <div className='Header_row3_col2'>
            <div>
              <img src={img1} />
            </div>
          </div>
        </div>
      </div>
    </div>
      {/* </div> */}
    </>
  );
}

export default Header;
