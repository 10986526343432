import React from 'react';
import {HashRouter,Routes,Route} from 'react-router-dom';
import Header from './components/Header';
import Homepage from './page/Homepage';
export default function Navigation(){
    return(
        <>
        <HashRouter>
            <Routes>
                <Route path="/" element={<Homepage/>}></Route>
            </Routes>
        </HashRouter>
        </>
    )
}